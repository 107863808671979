import React from 'react';
import {Button} from '@material-ui/core';
import WithTranslator from '../../providers/WithTranslator';
import WithTransferMode from '../../providers/WithTransferMode';

import './MainPart.scss';
import background from '../../resources/background.jpg';

class MainPart extends React.Component {
    componentDidMount() {
        this.transferPart = document.getElementById('transferPart');
    }

    goToTransfer = mode => {
        this.props.setTransferMode(mode);
        window.scrollTo({top: this.transferPart.offsetTop - 67, behavior: 'smooth'});
    }

    render() {
        const translator = this.props.translator;
        return (
            <div className='main-part' id='mainPart'>
                <img src={background} alt='Rsk' className='background'/>
                <div className='content'>
                    <span className='h2'>{translator('mainPart.title')}</span>
                    <span className='h3'>{translator('mainPart.description')}</span>
                    <div className='controls'>
                        <Button onClick={() => this.goToTransfer(0)}
                                className={`_large ${this.props.transferMode === 0 ? 'primary' : 'secondary _no-boarders'}`}>
                            {translator('mainPart.btn.makeTransfer')}
                        </Button>
                        <Button onClick={() => this.goToTransfer(1)}
                                className={`_large ${this.props.transferMode === 1 ? 'primary' : 'secondary _no-boarders'}`}>
                            {translator('mainPart.btn.receiveTransfer')}
                        </Button>
                    </div>
                </div>
            </div>
        )
    }
}

export default WithTransferMode(WithTranslator(MainPart));
